import { createRouter, createWebHistory } from 'vue-router'
import 'material-icons/iconfont/material-icons.css';
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import Search from '../views/Search.vue'

import Edit from '../views/Edit.vue'
import PreviousEntries from '../views/PreviousEntries.vue'


const routes = [
  {path: '/login', name: 'Login', component: Login},
  {path: '/search/:id', name: 'Search', component: Search},
  {path: '/edit/:id', name: 'Edit', component: Edit},
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/entries',
    name: 'PreviousEntries',
    component: PreviousEntries
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
