const toDate = (d) => {
    let firstSplit;
    let day;
    let month;
    let year;
    if(d && d.length > 0){
        if(String(d).split("").includes("T")){
            firstSplit = d.split("T")[0];
            day = firstSplit.split("-")[2];
            month = firstSplit.split("-")[1];
            year = firstSplit.split("-")[0];
        }else{
            firstSplit = String(d).split(" ")[0];
            day = firstSplit.split("-")[2];
            month = firstSplit.split("-")[1];
            year = firstSplit.split("-")[0];
        }
        return `${day}/${month}/${year}`
    }
    return ""
}
const toDateUS = (d) => {
    let firstSplit;
    let day;
    let month;
    let year;
    if(d && d.length > 0){
        if(String(d).split("").includes("T")){
            firstSplit = d.split("T")[0];
            day = firstSplit.split("-")[2];
            month = firstSplit.split("-")[1];
            year = firstSplit.split("-")[0];
        }else{
            firstSplit = String(d).split(" ")[0];
            day = firstSplit.split("-")[2];
            month = firstSplit.split("-")[1];
            year = firstSplit.split("-")[0];
        }
        return `${month}/${day}/${year}`
    }
    return ""
}

export { toDate, toDateUS}