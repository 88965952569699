import store from '../store/index'

export default {
    open(message){
        store.commit('setModalSuccess', {
            display: true,
            message: message,
        });
    },
    close(){
        store.commit('setModalSuccess', {
            display: false,
        });
    },
    openError(errorMessage){
        store.commit('setModalError', {
            display: true,
            errorMessage: errorMessage,
        });
    },
    closeError(){
        store.commit('setModalError', {
            display: false,
        });
    },
    openFavorites(){
        store.commit('setModalFavorites', {
            display: true,
        });
    },
    closeFavorites(){
        store.commit('setModalFavorites', {
            display: false,
        });
    },
    openFavoritesNickname(){
        store.commit('setModalFavoritesNickname', {
            display: true,
        });
    },
    closeFavoritesNickname(){
        store.commit('setModalFavoritesNickname', {
            display: false,
        });
    },
    openEditFavoritesNickname(id){
        store.commit('setModalEditFavoritesNickname', {
            display: true,
            id,
        });
    },
    closeEditFavoritesNickname(){
        store.commit('setModalEditFavoritesNickname', {
            display: false,
        });
    },
    openHowToAccess(){
        store.commit('setModalHowToAccess', {
            display: true,
        });
    },
    closeHowToAccess(){
        store.commit('setModalHowToAccess', {
            display: false,
        });
    },
    openConfirmation(title, message, confirmMessage, callbackOK, callbackCancel){
        store.commit('setModalConfirmation', {
            display: true,
            title: title,
            message: message,
            confirmMessage: confirmMessage,
            callbackOK: callbackOK,
            callbackCancel: callbackCancel,
        });
    },
    closeConfirmation(){
        store.commit('setModalConfirmation', {
            display: false,
        });
    },
    openConfirmationEntries(title, message, confirmMessage, callbackOK, callbackCancel){
        store.commit('setModalConfirmationEntries', {
            display: true,
            title: title,
            message: message,
            confirmMessage: confirmMessage,
            callbackOK: callbackOK,
            callbackCancel: callbackCancel,
        });
    },
    closeConfirmationEntries(){
        store.commit('setModalConfirmationEntries', {
            display: false,
        });
    },
    openFilterEntries(title, message, confirmMessage, callbackOK, callbackCancel){
        store.commit('setModalFilterEntries', {
            display: true,
            title: title,
            message: message,
            confirmMessage: confirmMessage,
            callbackOK: callbackOK,
            callbackCancel: callbackCancel,
        });
    },
    closeFilterEntries(){
        store.commit('setModalFilterEntries', {
            display: false,
        });
    },
}