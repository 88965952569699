<template>
  <div v-if="isMobile">
    <div class="carouselContainer">
        <section v-for="(item) in myweek" :key="item.id">
            <Day
              :date="item.date"
              :hour="item.hour"
              :name="item.name"
              :color="item.color"
              @click="select(item.id)"
            />
        </section>
    </div>
  </div>

  <div v-else>
    <div class="carouselContainer">

        <section v-for="(item) in myweek" :key="item.id">            
            <Day
              :date="item.date"
              :hour="item.hour"
              :name="item.name"
              :color="item.color"
              @click="select(item.id)"
            />

        </section>
    </div>
  </div>
</template>

<script>
import Day from "./Elements/Day.vue";


import { useStore } from 'vuex';
import { ref,onMounted,onBeforeUpdate } from 'vue';



export default {
  name: "CarouselDay",
  components: {
    Day,
  },
  props: {
    week: Array,
  },

  data() {
    return {
      isMobile: false,
    };
  },
  created() {
    this.isMobile = window.innerWidth < 690 ? true : false;
    window.addEventListener("resize", () => {
      this.isMobile = window.innerWidth < 690 ? true : false;
    });
  },


  setup(props) {
    const store = useStore(); 

    const myweek = ref([]);


    onMounted(async () => {
      myweek.value = []
      myweek.value = props.week

      if(myweek.value.length > 5)
      {
        console.log("fazendo o pop")
        for(let i = myweek.value.length; i > 5; i--)
        {
          myweek.value.pop(i);
        }
      }

    })

    onBeforeUpdate(() => {
      myweek.value = []
      myweek.value = props.week    
      
      if(myweek.value.length > 5)
        {

          for(let i = myweek.value.length; i > 5; i--)
          {
            myweek.value.pop(i);
          }
        }
      store.commit("day",'')
    })

    const select = (item)=>{
      console.log(item)
      store.commit("day",item)
    }

    return {
      select,
      myweek,

    };
  },
};
</script>

<style scoped>
.carouselContainer {
 
  display: flex;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
  margin: -5px;
}

</style>