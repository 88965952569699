import axios from "axios";
//import router from "../router";

const isProd = process.env.NODE_ENV === 'production';
//const isProd = false;

const instance = axios.create({
  baseURL: (isProd) ? 'https://apiprefab.herokuapp.com/api' : 'http://localhost:3000/api',
  headers: {
    Authorization: localStorage.getItem("t_prefab_webapp")
      ? `Bearer ${localStorage.getItem("t_prefab_webapp")}`
      : "",
      'Access-Control-Allow-Origin': '*'
  },
});

const GET = (url) =>
  instance
    .get(url || "")
    .then((res) => res.data)
    .then((res) => {
      return res;
    })
    .catch((e) => {
      throw e.response.data.message;
    });

const POST = (url, params) =>
  instance
    .post(url || "", params)
    .then((res) => res.data)
    .then((res) => {
      return res;
    })
    .catch((e) => {
      throw e.response.data.message;
    });

const PUT = (url, params) =>
  instance
    .put(url || "", params)
    .then((res) => res.data)
    .then((res) => {
      return res;
    })
    .catch((e) => {
      throw e.response.data.message;
    });

const PATCH = (url, params) =>
  instance
    .patch(url || "", params)
    .then((res) => res.data)
    .then((res) => {
      return res;
    })
    .catch((e) => {
      throw e.response.data.message;
    });

const DELETE = (url) =>
  instance
    .delete(url || "")
    .then((res) => res.data)
    .then((res) => {
      return res;
    })
    .catch((e) => {
      throw e.response.data.message;
    });

const LOGIN = (route, payload) =>
  instance
    .post(route, payload)
    .then((res) => res.data)
    .then((res) => {
      return res.token;
    })
    .catch((e) => {
      throw e.response.data.message;
    });

export { GET, POST, PUT, PATCH, DELETE, LOGIN, instance };
