<template>
    <div class="cardContainer">
        <h3> {{nickname}} </h3>

        <Button type="modalEditNickname" icon="edit" :click="() => openEditFavoritesNicknameModal(id)" />

        <p :class="cardText"> <span class="emphasis">Project:</span> {{project.name}} </p>
        <p :class="cardText"> <span class="emphasis">Activity:</span> {{activity.name}} </p>
        <p :class="cardText"> <span class="emphasis">Label:</span> {{label.name}} </p>
        <p :class="cardText"> <span class="emphasis">Category:</span> {{category.name}} </p>
        <p :class="cardText"> <span class="emphasis">Discipline:</span> {{discipline.name}} </p>
    </div>
    <div class="buttonsContainer">
        <Button type="modalFavoritesReverse" text="Delete" :click="() => openConfirmationModal(id)" />
        <Button type="modalFavorites" text="Select" :click="selectFavorite" />
    </div>
</template>

<script>
import Button from './Elements/Button.vue';
import { useStore } from 'vuex';
import { inject } from "vue";

export default {
    name: 'Card',
    props: {
        id: String,
        nickname: String,
        project: String,
        activity: String,
        label: String,
        category: String,
        discipline: String,
    },
    components: {
    Button,
},
    setup(props){
        const store = useStore();
        const loader = inject("loading");
        const modalEditFavoritesNickname = inject("ModalEditFavoritesNickname");
        const modalConfirmation = inject("modalConfirmation");

        const openConfirmationModal = async (id) => {
            loader.open();
            modalConfirmation.openConfirmation("Title", "Message", "Confirm Message", id);
            loader.close();
        }

        const selectFavorite =  () => {
            store.commit("setSearchProjects", props.project);
            store.commit("setSearchActivities", props.activity);
            store.commit("setSearchLabel", props.label);
            store.commit("setSearchCategories", props.category);
            store.commit("setSearchDisciplines", props.discipline);
        }

        const openEditFavoritesNicknameModal = async (id) => {
            loader.open();
            modalEditFavoritesNickname.openEditFavoritesNickname(id);
            loader.close();
        }
    return {selectFavorite, openConfirmationModal, openEditFavoritesNicknameModal};
    },
    
}
</script>

<style>
    .cardContainer{
        box-sizing: border-box;
        background-color: #E5E5E5;
        display: flex;
        flex-flow: column;
        align-items: flex-start;
        gap: 0.5rem;
        height: 80%;
        width: 90%;
        border-radius: 10px;
        cursor: pointer;
        padding: 1rem;
        position: relative;
        margin: 0.5rem 3rem 0.8rem 0.5rem;
        transition: background-color .35s ease-in-out, color .35s ease-in-out;
    }

    .cardContainer:hover{
        outline: none;
        box-shadow: 0 0 0 1px #E8BB00;
    }

    .cardContainer h3 {
        color: #676767;
        font-size: 1.2rem;
        font-weight: 500;
        margin: 0 auto 1rem;
    }

    .buttonsContainer{
        box-sizing: border-box;
        width: 90%;
        display: flex;
        flex-flow: row;
        align-items: center;
        justify-content: center;
    }

    .cardText{
        margin: 2rem;
    }

    .cardContainer p {
        margin-block-start: 0;
        margin-block-end: 0;
    }

    .emphasis{
        font-weight: 600;
    }
</style>