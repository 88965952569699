<template>
    <div v-if="store.getters.modalHowToAccess.display">
        <div class="modal-mask" @click="closeModal"></div>
        <div class="modal-wrapper">
            <div class="modal-container">
                <div class="body">
                    <slot name="body">
                        <h1 class="title-modal">How to access</h1>
                        <p class="instructions-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
                            Nullam dapibus mauris quis dui dignissim consectetur. 
                            In dictum nisi nec dui aliquam, eu efficitur felis volutpat. 
                            Integer sit amet pulvinar odio. Ut imperdiet leo ut purus lobortis, id lobortis erat vulputate. 
                            Proin id elit eu libero gravida cursus. 
                            Suspendisse efficitur, nulla vitae mollis dignissim, velit velit interdum arcu, fringilla tincidunt nulla augue ac sapien. 
                            Etiam consequat in arcu in dictum. Donec enim lacus, pellentesque ac sagittis in, aliquam id turpis. 
                            Nulla sit amet ultrices velit, interdum blandit diam. Aliquam vehicula sit amet augue sed vestibulum. 
                            Mauris sodales nulla at sem facilisis auctor. Sed eu dui orci. Vestibulum malesuada vel dolor vitae molestie. 
                            Aliquam sem leo, gravida eget mi tempor, pulvinar bibendum augue. Duis congue mauris eget erat tincidunt, pellentesque scelerisque erat imperdiet.</p>
                        <div class="btnContainer">
                            <Button type="modalContinue" text="Continue" :click="closeModal" />
                        </div>
                    </slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { useStore } from 'vuex';
import Button from './Button.vue';

export default {
    name: "ModalHowToAccess",
    components: {
        Button
    },
    setup() {
        const store = useStore();

        const closeModal = () => store.commit("setModalHowToAccess", false);

        return { closeModal, store }
    },
}
</script>

<style scoped>
    .modal-enter .modal-container,
    .modal-leave-active {
        opacity: 0;
    }

    .body {
        max-height: calc(100vh - 150px);
        /* overflow-y: auto; */
    }

    .modal-wrapper {
        display: table-cell;
        vertical-align: middle;
    }

    .modal-mask{
        position: fixed;
        z-index: 99999;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.6);
        display: table;
        transition: opacity 0.3s ease;
    }

    .modal-container{
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #fff;
        width: 80vw;
        max-height: 83vh;
        padding: 2em 1rem;
        margin: auto;
        border-radius: 10px;
        z-index: 99999;
        overflow: auto;
    }

    .modalIconHeader{
        font-size: 200px;
        color: #68ad47;
    }
    
    .title-modal{
        color: #272727;
        text-align: center;
        font-weight: 700;
        font-size: 24px;
        margin-bottom: 1em;
    }

    .subtitle-modal{
        text-align: center;
        color: rgba(0, 0, 0, 0.5);
        font-size: 15px;
        margin-bottom: 2rem;
    }

    .inputContainer{
        display: flex;
        flex-flow: column;
        align-items: flex-start;
        justify-content: center;
    }

    .label{
        color: rgba(0, 0, 0, 0.5);
        font-size: 14px;
        margin-bottom: .25rem;
    }

    .input{
        border: none;
        background-color: #F0F0F0;
        border-radius: 10px;
        width: 100%;
        margin-bottom: 1rem;
        font-size: 14px;
        height: 50px;
        padding-left: .8rem;
    }

    .input:focus{
        outline: none;
        box-shadow: 0 0 0 1px #0066FF;
    }

    .btnContainer{
        display: flex;
        flex-flow: column;
    }

    .useTerms{
        display: flex;
        justify-content: left;
        align-items: center;
        margin: 1rem 0 2rem 0;
        font-size: 13px;
    }

    .emphasis{
        margin-left: .3rem;
        color: #0066FF;
        font-weight: 700;
        text-decoration-line: underline;
        cursor: pointer;
    }

    .input-useTerms{
        width: .1px;
        opacity: 0;
    }
    .inputCustom{
        display: inline-block;
        width: 19px;
        height: 19px;
        margin-right: .7rem;
        background: #E5E5E5;
        border-radius: 5px;
        position: relative;
    }

    .input-useTerms:checked + .inputCustom .check{
        border-left: 3px solid #0066FF;
        border-bottom: 3px solid #0066FF;
        position: absolute;
        left: 20.83%;
        right: 20.83%;
        top: 36%;
        bottom: 29.17%;
        transform: rotate(-45deg);
        border-radius: .8px;
    }

    @media screen and (min-width: 690px) {

        .modal-container{
            width: 515px;
            padding: 3rem 2.5em;
        }
    }

    @media screen and (min-width: 1025px) {

        .modal-container{
            height: 93vh;
            max-height: none;
        }
    }


</style>
