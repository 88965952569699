<template>
    <div v-if="store.getters.modalConfirmationEntries.display">
        <div class="modal-mask" @click="closeModal"></div>
        <div class="modal-wrapper">
            <div class="modal-container">
                <div class="body">
                    <slot name="body">
                        <h1 class="title-modal">Are you sure?</h1>
                        <div class="buttonsContainer">
                            <Button type="modalFavoritesReverse" text="Cancel" :click="closeModal" />
                            <Button type="modalFavorites" text="Confirm" :click="action" />
                        </div>
                    </slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { inject } from '@vue/runtime-core';
import { useStore } from 'vuex';
import Button from './Button.vue';
import { DELETE } from '@/services/api';
import { useRouter } from 'vue-router';

export default {
    name: "ModalConfirmationEntries",
    components: {
        Button
    },
    setup() {
        const store = useStore();
        const loader = inject("loading");
        const router = useRouter();
        const modalSuccess = inject("modalSuccess");
        const modalError = inject("modalError");

        const closeModal = () => store.commit("setModalConfirmationEntries", false);
        const action = async () => {
            const id = store.getters.modalConfirmationEntries.callbackOK;
            try{
                await DELETE(`hours_record/${id}`);
                modalSuccess.open("Entry was deleted!");
                router.push({path: '/'});
                loader.close();
            } catch (e) {
                console.log(e);
                loader.close();
                if(e.includes("Token") || e == "Unauthorized") {
                    localStorage.removeItem("t_prefab_webapp");
                    localStorage.removeItem("info_prefab_webapp");
                    modalError.openError("Unauthorized");
                    router.push({ path: '/login'});
                }
            }
            closeModal();

            /*const favs = JSON.parse(localStorage.getItem("favorites"));
            const index = favs.findIndex(fav => fav.id === id);
            if(index != -1) {
                favs.splice(index, 1);
                store.commit("setFavorite", favs);
                localStorage.setItem("favorites", JSON.stringify(favs));
            }*/
        }
        return { closeModal, store, modalSuccess, action }
    },
}
</script>

<style scoped>
    .modal-enter .modal-container,
    .modal-leave-active {
        opacity: 0;
    }

    .body {
        max-height: calc(100vh - 150px);
        /* overflow-y: auto; */
    }

    .buttonsContainer{
        display: flex;
        flex-flow: row;
        align-items: center;
        justify-content: center;
    }

    .modal-wrapper {
        display: table-cell;
        vertical-align: middle;
    }

    .modal-mask{
        position: fixed;
        z-index: 99999;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.6);
        display: table;
        transition: opacity 0.3s ease;
    }

    .modal-container{
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #fff;
        width: 80vw;
        max-height: 83vh;
        padding: 2em 1rem;
        margin: auto;
        border-radius: 10px;
        z-index: 99999;
        overflow: auto;
    }

    .modalIconHeader{
        font-size: 200px;
        color: #68ad47;
    }
    
    .title-modal{
        color: #272727;
        text-align: center;
        font-weight: 700;
        font-size: 24px;
        margin-bottom: 1em;
    }

    .subtitle-modal{
        text-align: center;
        color: rgba(0, 0, 0, 0.5);
        font-size: 15px;
        margin-bottom: 2rem;
    }

    .inputContainer{
        display: flex;
        flex-flow: column;
        align-items: flex-start;
        justify-content: center;
    }

    .label{
        color: rgba(0, 0, 0, 0.5);
        font-size: 14px;
        margin-bottom: .25rem;
    }

    .input{
        border: none;
        background-color: #F0F0F0;
        border-radius: 10px;
        width: 100%;
        margin-bottom: 1rem;
        font-size: 14px;
        height: 50px;
        padding-left: .8rem;
    }

    .input:focus{
        outline: none;
        box-shadow: 0 0 0 1px #0066FF;
    }

    .btnContainer{
        display: flex;
        flex-flow: column;
    }

    .useTerms{
        display: flex;
        justify-content: left;
        align-items: center;
        margin: 1rem 0 2rem 0;
        font-size: 13px;
    }

    .emphasis{
        margin-left: .3rem;
        color: #0066FF;
        font-weight: 700;
        text-decoration-line: underline;
        cursor: pointer;
    }

    .input-useTerms{
        width: .1px;
        opacity: 0;
    }
    .inputCustom{
        display: inline-block;
        width: 19px;
        height: 19px;
        margin-right: .7rem;
        background: #E5E5E5;
        border-radius: 5px;
        position: relative;
    }

    .input-useTerms:checked + .inputCustom .check{
        border-left: 3px solid #0066FF;
        border-bottom: 3px solid #0066FF;
        position: absolute;
        left: 20.83%;
        right: 20.83%;
        top: 36%;
        bottom: 29.17%;
        transform: rotate(-45deg);
        border-radius: .8px;
    }

    @media screen and (min-width: 690px) {

        .modal-container{
            width: 515px;
            padding: 3rem 2.5em;
        }
    }

    @media screen and (min-width: 1025px) {

        .modal-container{
            height: 93vh;
            max-height: none;
        }
    }


</style>
