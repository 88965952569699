<template>
  <button v-if="type === 'login'" type="button" class="btnLogin" @click="click">
    {{ text }}
  </button>

  <button v-if="type === 'edit'" type="button" class="btnEdit" @click="click">
    {{ text }}
  </button>

  <button v-if="type === 'modalContinue'" type="button" class="btnModalContinue" @click="click">
    {{ text }}
  </button>

  <button v-if="type === 'modalFavorites'" type="button" class="btnModalFavorites" @click="click">
    {{ text }}
  </button>

  <button v-if="type === 'modalFavoritesReverse'" type="button" class="btnModalFavoritesReverse" @click="click">
    {{ text }}
  </button>
  
  <button v-if="type === 'modalEditNickname'" type="button" class="btnModalEditNickname" @click="click">
    <span class="material-icons-round iconHeader">{{icon}}</span>
  </button>

  <button v-if="type === 'floatingButton'" type="button" class="btnFloating" @click="click">
    <div class="iconContainer d-flex align-items-center">
    <span class="material-icons-round iconHeader">{{icon}}</span>
    </div>
  </button>
</template>

<script>
export default {
  props: {
    text: String || null,
    type: String || null,
    click: Function || null,
    icon: String || null,
  },
  setup(props) {
    return { props };
  },
};
</script>

<style scoped>
  .btnLogin {
    background-color: #E8BB00;;
    border-radius: 30px;
    padding: .8rem 0;
    color: #fff;
    width: 95%;
    border: none;
    font-size: 0.8rem;
    font-weight: 600;
    margin-top: .5rem;
    cursor: pointer;
    transition: all ease 0.2s;
  }
  .btnLogin:hover {
    background: #ddb102;
  }

  .btnEdit {
    background-color: #E8BB00;;
    border-radius: 30px;
    padding: .8rem 0;
    color: #fff;
    width: 100%;
    border: none;
    font-size: 0.8rem;
    font-weight: 600;
    margin-top: .5rem;
    cursor: pointer;
    transition: all ease 0.2s;
  }
  .btnEdit:hover {
    background: #ddb102;
  }

  .btnModalContinue {
    background-color: #E8BB00;;
    border-radius: 30px;
    padding: .8rem 0;
    color: #fff;
    width: 50%;
    border: none;
    font-size: 0.8rem;
    font-weight: 600;
    margin-top: .5rem;
    cursor: pointer;
    transition: all ease 0.2s;
  }
  .btnModalContinue:hover {
    background: #ddb102;
  }

  .btnModalFavorites {
    background-color: #E8BB00;;
    border-radius: 30px;
    padding: .8rem 0;
    color: #fff;
    width: 40%;
    border: none;
    font-size: 0.8rem;
    font-weight: 600;
    margin-top: .1rem;
    margin-bottom: .4rem;
    margin-left: .3rem;
    margin-right: .3rem;
    cursor: pointer;
    transition: all ease 0.2s;
  }
  .btnModalFavorites:hover {
    background: #ddb102;
  }

  .btnModalFavoritesReverse {
    background-color: #fff;
    border: 1px solid;
    border-radius: 30px;
    border-color: #E8BB00;
    border-width: 0px;
    box-shadow:inset 0 0 0 3px #E8BB00;
    padding: .8rem 0;
    color: #E8BB00;
    width: 40%;
    font-size: 0.8rem;
    font-weight: 600;
    margin-top: .1rem;
    margin-bottom: .4rem;
    margin-left: .3rem;
    margin-right: .3rem;
    cursor: pointer;
    transition: all ease 0.2s;
  }
  .btnModalFavoritesReverse:hover {
    opacity: 0.8;
  }

  .btnFloating {
    position: fixed;
    right: 0;
    bottom: 0;
    margin: 0.8rem;
    z-index: 10000;
    border-radius: 100px;
    height: 75px;
    width: 75px;
    background-color: #E8BB00;
    color: black;
    border-color: #fff;
    cursor: pointer;
    transition: all ease 0.2s;
  }
  .btnFloating:hover {
    background: #ddb102;
  }

  .iconHeader{
    color: rgb(69, 69, 69);
    font-size: 33px;
    margin-bottom: 0.5rem;
  }

  .btnModalEditNickname {
    width: 2rem;
    height: 2rem;
    background: #E8BB00;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    padding: 0.5rem;
    border: 0;
    border-radius: 6px;

    position: absolute;
    top: 0.875rem;
    right: 0.875rem;

    transition: all ease 0.2s;
    cursor: pointer;
  }

  .btnModalEditNickname:hover {
    opacity: 0.75;
  }

  .btnModalEditNickname span {
    color: #fff;
    font-size: 1.2rem;
  }


   @media screen and (max-width: 690px) {

  .btnFloating {
      position: fixed;
      right: 0;
      bottom: 0;
      margin: 0.8rem;
      z-index: 10000;
      border-radius: 100px;
      height: 75px;
      width: 75px;
      background-color: #E8BB00;
      color: black;
      border-color: #fff;
      cursor: pointer;
      transition: all ease 0.2s;
      
    }

   }
</style>
