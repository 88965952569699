const isEmpty = (payload)  => {
    const emptyFields = []
    Object.entries(payload).forEach(element => {
        if(element[1] === "" || element[1] === '0' || element[1] == undefined){
            element[0] !== 'deleted_at' && emptyFields.push(element[0]);
        }
    });
    let returnMessage = ""
    emptyFields.forEach(el => {
        el === 'email' ? el = 'Email' : el;
        el === 'password' ? el = 'Password' : el;

        returnMessage += `* The <b>${el}</b> field is empty <br/>`
    })
    return returnMessage
}

const isEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

const isNumber = (value) => {
    return Number.isInteger(value);
}

export {isEmpty, isEmail, isNumber}