<template>
    <div v-if="store.getters.modalFilterEntries.display">
        <div class="modal-mask" @click="closeModal"></div>
        <div class="modal-wrapper">
            <div class="modal-container">
                <div class="body">
                    <slot name="body">
                        <h1 class="title-modal">Date filter</h1>
                            <p class="text">Select date</p>    
                            <div class="flex-1 mr-3 mb-2">
                                <Input
                                type="date"
                                label=""
                                v-model="finalDate"
                                :minDate="initialDate"
                                />
                            </div>
                        <div class="buttonsContainer">
                            <Button type="modalFavorites" text="Apply" :click="apply" />
                            <!--<Button type="modalFavoritesReverse" text="Clear" :click="clear" /> -->
                            <Button type="modalFavoritesReverse" text="Exit" :click="clear" />
                        </div>
                    </slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { inject, onMounted, ref } from '@vue/runtime-core';
import { useStore } from 'vuex';
import Button from './Button.vue';
import Input from './Input.vue';
import { useRouter } from 'vue-router';
//import { dateToBackend } from '@/services/util';
import { isEmpty } from '@/services/validate';

export default {
    name: "ModalFilterEntries",

    components: {
        Button,
        Input
    },
    setup() {
        const store = useStore();
        const loader = inject("loading");
        const router = useRouter();
        const modalSuccess = inject("modalSuccess");
        const modalError = inject("modalError");
        const finalDate = ref("");

        onMounted(async () => {
            
        })
        
        const openErrorModal = async (message) => {
            loader.open();
            modalError.openError(message);
            loader.close();
        }

        const closeModal = () => store.commit("setModalFilterEntries", false);
        const apply = async () => {
            loader.open();

            const validateFinalDate = isEmpty(finalDate);
            if (validateFinalDate){
                validateFinalDate && openErrorModal("Fill all fields to continue");
            } else {
            try{
                store.commit("filterDate",finalDate.value);
                closeModal();
                loader.close();
            } catch (e) {
                console.log(e);
                loader.close();
                if(e.includes("Token") || e.includes("t_prefab_webapp")|| e == "Unauthorized") {
                    localStorage.removeItem("t_prefab_webapp");
                    localStorage.removeItem("info_prefab_webapp");
                    modalError.openError("Unauthorized");
                    router.push({ path: '/login'});
                }
            }
            closeModal();
            }

        }
        const clear = async () => {
            loader.open();
           /* let date = new Date();
            let finalDateArray = date.toLocaleDateString();

            finalDate.value = dateToBackend(finalDateArray);
            store.commit("filterDate",finalDate.value);


            finalDate.value = "";*/
            loader.close();
            closeModal();
        }

        return { closeModal, store, modalSuccess, apply, finalDate, clear }
    },
}
</script>

<style scoped>
    .modal-enter .modal-container,
    .modal-leave-active {
        opacity: 0;
    }

    .body {
        max-height: calc(100vh - 150px);
        /* overflow-y: auto; */
    }

    .text{
        margin: 1.5rem 0 0.5rem 0 ;
    }

    .buttonsContainer{
        width: 100%;
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: center;
        margin: 1.5rem 0 0.5rem 0;
    }

    .modal-wrapper {
        display: table-cell;
        vertical-align: middle;
    }

    .modal-mask{
        position: fixed;
        z-index: 99998;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.6);
        display: table;
        transition: opacity 0.3s ease;
    }

    .modal-container{
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #fff;
        width: 80vw;
        max-height: 83vh;
        padding: 2em 1rem;
        margin: auto;
        border-radius: 10px;
        z-index: 99998;
        overflow: auto;
    }

    .modalIconHeader{
        font-size: 200px;
        color: #68ad47;
    }
    
    .title-modal{
        color: #272727;
        text-align: center;
        font-weight: 700;
        font-size: 24px;
        margin-bottom: 1em;
    }

    .subtitle-modal{
        text-align: center;
        color: rgba(0, 0, 0, 0.5);
        font-size: 15px;
        margin-bottom: 2rem;
    }

    .inputContainer{
        display: flex;
        flex-flow: column;
        align-items: flex-start;
        justify-content: center;
    }

    .label{
        color: rgba(0, 0, 0, 0.5);
        font-size: 14px;
        margin-bottom: .25rem;
    }

    .input{
        border: none;
        background-color: #F0F0F0;
        border-radius: 10px;
        width: 100%;
        margin-bottom: 1rem;
        font-size: 14px;
        height: 50px;
        padding-left: .8rem;
    }

    .input:focus{
        outline: none;
        box-shadow: 0 0 0 1px #0066FF;
    }

    .btnContainer{
        display: flex;
        flex-flow: column;
    }

    .useTerms{
        display: flex;
        justify-content: left;
        align-items: center;
        margin: 1rem 0 2rem 0;
        font-size: 13px;
    }

    .emphasis{
        margin-left: .3rem;
        color: #0066FF;
        font-weight: 700;
        text-decoration-line: underline;
        cursor: pointer;
    }

    .input-useTerms{
        width: .1px;
        opacity: 0;
    }
    .inputCustom{
        display: inline-block;
        width: 19px;
        height: 19px;
        margin-right: .7rem;
        background: #E5E5E5;
        border-radius: 5px;
        position: relative;
    }

    .input-useTerms:checked + .inputCustom .check{
        border-left: 3px solid #0066FF;
        border-bottom: 3px solid #0066FF;
        position: absolute;
        left: 20.83%;
        right: 20.83%;
        top: 36%;
        bottom: 29.17%;
        transform: rotate(-45deg);
        border-radius: .8px;
    }

    @media screen and (min-width: 690px) {

        .modal-container{
            width: 515px;
            padding: 3rem 2.5em;
        }
    }

    @media screen and (min-width: 1025px) {

        .modal-container{
            height: 93vh;
            max-height: none;
        }
    }


</style>
