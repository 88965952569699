<template>
    <div :style="`height: ${height}px`" class="container">
        <img src="../assets/pfl-logo1.png" alt="Logotipo Prefablogic" class="image" />
    </div>
</template>

<script>

export default{
    name: 'Header',
    props: {
        height: Number
    },
    setup() {

    },
}
</script>

<style scoped>
    .container{
        background-color: #FFF;
        width: 100vw;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.06);
        border-radius: 0 0 30px 30px;
    }

    .image{
        width: auto;
        height: 70px;
        margin: 0 auto 1em auto;
    }
    
</style>
