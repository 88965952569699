<template>
  <div :class="`loaderContainer ${store.getters.loading ? 'block' : 'hidden'}`">
      <img class="image" src='../../assets/loader.gif'/>
  </div>
</template>

<script>
import { useStore } from 'vuex'
export default {
  name: 'loading',
  setup(){
    const store = useStore()
    return { store }
  }
}
</script>

<style scoped>
.loaderContainer{
    width: 100%;
    height: 100vh;
    position: fixed;
    background-color: #000;
    opacity: .7;
    z-index: 9999999999999 !important;
}

.image{
    width: 8%;
    user-select: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.block{
  display: block;
}

.hidden{
  display: none;
}

</style>