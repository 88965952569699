<template>
<div class="edit">
    <Header :height="150" />
      <section class="topTextContainer">
        <h2 class="title">Edit entry</h2>
      </section>
    <div class="inputContainer">
      <Input class="customDate" icon="calendar_today" placeholder="Date" type="customDate" v-model="data.worked_date" /> 
      <div class="selectableContainer" @click="() => navigationEdit('projects')">
        <Input  type="withIcon" v-model="store.getters.editProjects.name" placeholder="Choose Project" icon="all_inbox" />
      </div>
      <div class="selectableContainer" @click="() => navigationEdit('activities')">
        <Input type="withIcon" v-model="store.getters.editActivities.name" placeholder="Activity" icon="trending_up"/>
      </div>
      <div class="selectableContainer" @click="() => navigationEdit('label')">
        <Input v-model="store.getters.editLabel.name" type="withIcon" placeholder="Label" icon="label_important"/>
      </div>
      <div class="selectableContainer" @click="() => navigationEdit('categories')">
        <Input type="withIcon" v-model="store.getters.editCategories.name" placeholder="Category" icon="workspaces"/>
      </div>
      <div class="selectableContainer" @click="() => navigationEdit('disciplines')">
        <Input type="withIcon" v-model="store.getters.editDisciplines.name" placeholder="Discipline" icon="view_in_ar"/>
      </div>
        <Input type="withIcon" v-model="data.description" placeholder="Description" icon="menu_book"/>
        <Input type="customTime" v-model="data.worked_hour" placeholder="Inform time spent in job" icon="timer"/>

        <Button type="edit" text="Save changes" :click="editRecord" />
    </div>
      <div class ="lastEntries">
        <p class="lastEntries" @click="backToMain">Don't save</p>
      </div>
    <div class="btnContainer">
        <Button type="modalUser" text="Continue" :click="() => {}" />
    </div>
</div>
</template>

<script>
import Header from '../components/Header.vue';
import Button from '../components/Elements/Button.vue';
import Input from '../components/Elements/Input.vue';
import { inject, onMounted, onUnmounted, ref } from "vue";
import { useRouter, useRoute } from 'vue-router';
import { isEmpty, /*isNumber*/ } from '../services/validate';
import { PATCH } from "../services/api";
import { useStore } from 'vuex';

export default {
    name: 'Edit',
    components: {
        Header,
        Button,
        Input
    },
    setup(){
      const loader = inject("loading");
      const router = useRouter();
      const route = useRoute();
      const store = useStore();

      const user = JSON.parse(localStorage.getItem("info_prefab_webapp"));
      //let date = "";

      const data = ref({
        worked_hour: "",
        worked_date: "",
        description: "",
      })

      const modal = inject("modalSuccess");
      const modalError = inject("modalError");

      const navigationEdit = (path) => {
        loader.open();
        localStorage.setItem("record_id", route.params.id);
        router.push(`/search/${path}`);
        loader.close();
      }

      const openSuccessModal = async () => {
        loader.open();
        //store.commit("setModalSuccess", true);
        modal.open("Changes saved successfully!");
        loader.close();
      }

      const openErrorModal = async () => {
        loader.open();
        //store.commit("setModalSuccess", true);
        modalError.openError("Something went wrong");
        loader.close();
      }

      const backToMain = async () => {
        loader.open();
        localStorage.removeItem("record_id");
        router.push("/");
        loader.close();
      }

      onMounted(async () => {
        loader.open();
        //date = new Date();
        //date.setDate(date.getDate());

        const editDate = store.getters.editWorkedDate.split('T')
        
        data.value.worked_hour = store.getters.editWorkedHour;
        data.value.worked_date = editDate[0];
        data.value.description = store.getters.editDescription;
        loader.close();
      })

      onUnmounted(async () => {
        store.commit("setEditDescription", data.value.description);
        store.commit("setEditWorkedHour", data.value.worked_hour);
        store.commit("setEditWorkedDate", data.value.worked_date);
      })

      const editRecord = async () => {
        store.commit("setEditDescription", data.value.description);
        store.commit("setEditWorkedHour", data.value.worked_hour);
        store.commit("setEditWorkedDate", data.value.worked_date);

        const obj = {
          worked_date: store.getters.editWorkedDate,
          worked_hour: store.getters.editWorkedHour,
          description: store.getters.editDescription,
          projects_id: store.getters.editProjects,
          activities_id: store.getters.editActivities,
          label_id: store.getters.editLabel,
          categories_id: store.getters.editCategories,
          disciplines_id: store.getters.editDisciplines,
          users_id: user.sub,
        }
        console.log(obj)
        let minutes = obj.worked_hour;
        if (minutes == null || minutes == ""){
            obj.worked_hour = "";
            return;
        } else if (minutes.length == 1){
            let newMinutes = "0" + minutes + ":00";
            minutes = newMinutes;
        } else if (minutes.length == 3){
            let newMinutes = minutes + "00";
            minutes = newMinutes;
        } else if (minutes.length == 4){
            let newMinutes = minutes + "0";
            minutes = newMinutes;
        }
        minutes = minutes.split(":");
        let correctMinutes = minutes[1];
        let correctHours = minutes[0]
        if (correctMinutes > 59){
            correctMinutes = 59;
        }
        if (correctHours > 23){
            correctHours = 23;
        }
        const correctTime = correctHours + ":" + correctMinutes;
        obj.worked_hour = correctTime;

        const validate = isEmpty(obj);
        //const validateHours = isNumber(obj.worked_hour);

        if (validate /*&& !validateHours*/) {
          validate && openErrorModal();
        } else {
          loader.open();
          try {
            const objCopy = { ...obj, description: data.value.description };
            await PATCH(`hours_record/${route.params.id}`, objCopy);
            localStorage.removeItem("record_id");
            openSuccessModal();
            router.push({ path: '/'});
            loader.close();
          } catch (e) {
            console.log(e);
            loader.close();
            if(e.includes("Token") || e.includes("t_prefab_webapp")|| e == "Unauthorized") {
              localStorage.removeItem("t_prefab_webapp");
              localStorage.removeItem("info_prefab_webapp");
              modalError.openError("Unauthorized");
              router.push({ path: '/login'});
            }
            openErrorModal();
          }
        }
      }

      return{ navigationEdit, openSuccessModal, openErrorModal, backToMain, editRecord, data, store }
    },
    created() {
    this.isMobile = window.innerWidth < 800 ?  true : false;
    window.addEventListener('resize', () => {
      this.isMobile = window.innerWidth < 800 ?  true : false;
    })
  },
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;400;500;600;700&display=swap');
html {
  scroll-behavior: smooth;
  box-sizing: border-box;
}

body {
  margin: 0;
}

.edit{
        background-color: #E5E5E5;
        height: 100vh;
        overflow: auto;
    }

.btnContainer{
  display: flex;
  flex-flow: column;
  align-items: center;
}

.inputContainer{
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    justify-content: center;
    width: 80%;
    margin: 0 auto;
}

.favoriteIconContainer{
    color: #8f969e;
    display: flex;
    flex-flow: row;
    align-items: flex-start;
    justify-content: center;
}

.saveFavoriteContainer{
    color: #8f969e;
    display: flex;
    flex-flow: row;
    align-items: flex-start;
    justify-content: center;
    position: relative;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.saveFavoriteContainer input{
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 9px;
  left: 4px;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  background-color: #8f969e;
}

.saveFavoriteContainer:hover input ~ .checkmark {
  background-color: #bebebe;
}

.saveFavoriteContainer input:checked ~ .checkmark {
  background-color: #E8BB00;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.saveFavoriteContainer input:checked ~ .checkmark:after {
  display: block;
}

.saveFavoriteContainer .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.iconHeader{
    font-size: 25px;
    padding: .6rem .3rem 0 .3rem;
}

.topTextContainer{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  width: 80%;
  margin: 0 auto;
}

.label{
    color: #fff;
    font-size: 14px;
    margin-bottom: .25rem;
}

.saveFavorite{
    color: #8f969e;
    font-weight: 200;
    font-size: .875rem;
    cursor: pointer;
}

.favoriteButton{
    color: #8f969e;
    font-weight: 200;
    font-size: 1rem;
    cursor: pointer;
}

.lastEntries{
    color: #E8BB00;
    font-weight: 600;
    font-size: .875rem;
    cursor: pointer;
    text-align: center;
    margin: auto;
    width: 60%;
    margin-top: 1rem;
    margin-bottom: 1rem;
}
</style>