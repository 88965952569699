<template>
  <ModalSuccess />
  <ModalError />
  <ModalFavorites />
  <ModalFavoritesNickname />
  <ModalEditNicknameFavorite />
  <ModalHowToAccess />
  <ModalConfirmation />
  <ModalConfirmationEntries />
  <ModalFilterEntries />
  <Loading />
  <div>
    <router-view />
  </div>
</template>

<script>
import { onMounted } from '@vue/runtime-core'
import ModalSuccess from './components/Elements/ModalSuccess.vue'
import ModalError from './components/Elements/ModalError.vue'
import ModalFavorites from './components/Elements/ModalFavorites.vue'
import ModalFavoritesNickname from './components/Elements/ModalFavoritesNickname.vue'
import ModalEditNicknameFavorite from './components/Elements/ModalEditNicknameFavorite.vue'
import ModalHowToAccess from './components/Elements/ModalHowToAccess.vue'
import ModalConfirmation from './components/Elements/ModalConfirmation.vue'
import ModalConfirmationEntries from './components/Elements/ModalConfirmationEntries.vue'
import ModalFilterEntries from './components/Elements/ModalFilterEntries.vue'
import Loading from './components/Loading/index.vue'
//import { useStore } from 'vuex';
import { useRouter } from 'vue-router'

export default {
  name: "App",
  components:{
    ModalSuccess,
    ModalError,
    ModalFavorites,
    ModalFavoritesNickname,
    ModalEditNicknameFavorite,
    ModalHowToAccess,
    ModalConfirmation,
    ModalConfirmationEntries,
    ModalFilterEntries,
    Loading,
},
  setup() {
    onMounted(() => {
      //const store = useStore();
      const router = useRouter();
      
      /* let favs = localStorage.getItem("favorites") ? JSON.parse(localStorage.getItem("favorites")) : []
      store.commit("setFavorite", favs); */

      const isAuth = localStorage.getItem("t_prefab_webapp");
      isAuth ? router.push('/') : router.push('/login');
    })
  },
}
</script>


<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
