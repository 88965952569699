<template>
    <template v-if="type == 'default'">
        <input
            v-if="readonly"
            class="input"
            type="text"
            :placeholder="placeholder"
            :value="modelValue"
            @input="(event) => $emit('update:modelValue', event.target.value)"
            readonly
        />
        <input
            v-else
            class="input"
            :type="type"
            :placeholder="placeholder"
            :value="modelValue"
            @input="(event) => $emit('update:modelValue', event.target.value)"
        />
    </template>

    <template v-if="type == 'withIcon'">
        <div class="iconContainer d-flex align-items-center">
            <span class="material-icons-round iconHeader">{{icon}}</span>
                <input
                    v-if="readonly"
                    class="inputIcon"
                    :type="type"
                    :placeholder="placeholder"
                    :value="modelValue"
                    :maxlength="50"
                    @input="(event) => $emit('update:modelValue', event.target.value)"
                    readonly
                />
                <input
                    v-else
                    class="inputIcon"
                    :type="type"
                    :placeholder="placeholder"
                    :value="modelValue"
                    :maxlength="50"
                    @input="(event) => $emit('update:modelValue', event.target.value)"
                />
        </div>
    </template>

    <template v-if="type == 'password'">
        <input
            class="input"
            :type="type"
            :placeholder="placeholder"
            :value="modelValue"
            @input="(event) => $emit('update:modelValue', event.target.value)"
            autocomplete="new-password"
        />
    </template>

    <template v-if="type == 'customTime'">
        <div class="iconContainer d-flex align-items-center">
            <span class="material-icons-round iconHeader">{{icon}}</span>
            <input
                v-if="readonly"
                class="inputIcon"
                :type="type"
                :placeholder="placeholder"
                :value="modelValue"
                @input="(event) => $emit('update:modelValue', event.target.value)"
                readonly
                disabled
            />
            <input
                v-else
                class="inputIcon"
                :type="type"
                locale="en"
                v-maska="'##:##'"
                :placeholder="placeholder"
                :value="modelValue"
                @blur="handleBlur"
                @input="(event) => $emit('update:modelValue', event.target.value)"
                :autocomplete="autocomplete"
            />
        </div>

    </template>
      <template v-if="type == 'date'">
        <label class="block relative">
            <span class="text-gray-700 bg-white px-3 -top-2 left-4 absolute text-sm">{{
            label
            }}</span>
            <input
            class="border rounded-lg py-4 appearance-none focus:outline-white form-input mt-1 pl-6 pr-4 block w-full date"
            :type="type"
            locale="en"
            :placeholder="placeholder"
            :value="modelValue"
            :max="maxDate"
            :min="minDate"
            @input="(event) => $emit('update:modelValue', event.target.value)"
            />
        </label>
    </template>

        <template v-if="type == 'customDate'">
        <div class="iconContainer d-flex align-items-center">
            <span class="material-icons-round iconHeader">{{icon}}</span>
            <input 
                class="inputIcon"
                type="date"
                locale="en"
                :placeholder="placeholder"
                :value="modelValue"
                :max="maxDate"
                :min="minDate"
                @input="(event) => $emit('update:modelValue', event.target.value)"
            />
        </div>
            </template>

         <template v-if="type == 'filterProject'">
            <div class="iconContainerFilter d-flex align-items-center">
                <span class="material-icons-round iconHeader">{{icon}}</span>
                    <input
                        class="inputIconFilter"
                        :type="type"
                        :placeholder="placeholder"
                        :value="modelValue"
                        :maxlength="50"
                        @input="(event) => $emit('update:modelValue', event.target.value)"
                    />
            </div>
             </template>

</template>



<script>

export default {
    name: 'Input',
    props: {
        type: String,
        label: String,
        placeholder: String,
        modelValue: String,
        icon: String,
        maxDate: String || null,
        minDate: String || null,
    },
    methods: {
    handleBlur(e) {
        let minutes = e.target.value;
        if (minutes == null || minutes == ""){
            e.target.value = "";
            return;
        } else if (minutes.length == 1){
            let newMinutes = "0" + minutes + ":00";
            minutes = newMinutes;
        } else if (minutes.length == 3){
            let newMinutes = minutes + "00";
            minutes = newMinutes;
        } else if (minutes.length == 4){
            let newMinutes = minutes + "0";
            minutes = newMinutes;
        }
        minutes = minutes.split(":");
        let correctMinutes = minutes[1];
        let correctHours = minutes[0]
        if (correctMinutes > 59){
            correctMinutes = 59;
        }
        if (correctHours > 23){
            correctHours = 23;
        }
        const correctTime = correctHours + ":" + correctMinutes;
        e.target.value = correctTime;
        e.target.modelValue = correctTime;
        }
    },
    data() {
    },
    setup(props) {
        return { props }
    },
}
</script>

<style scoped>
    .input{
        box-sizing: border-box;
        background-color: #FFF;
        border-radius: 10px;
        border: none;
        padding: 1rem 0 1rem 1rem;
        margin: .5rem 0;
        width: 95%;
    }
    .input:focus{
        outline: none;
        box-shadow: 0 0 0 1px #E8BB00;
    }
    .input::placeholder{
        color: #A0A0A099;
;
    }
    .inputIcon{
        border: none;
        width: 100%;
        position: relative;
    }
    .inputIcon:focus{
        outline: none;
    }
    .inputIcon::placeholder{
        color: #A0A0A099;
    }
    .iconContainer{
        display: flex;
        margin: .5rem;
        width: 100%;
        cursor: pointer;
        background-color: #FFF;
        border-radius: 10px;
        padding: .6rem 0 .6rem 0;
        margin: .4rem 0;
    }
    .iconContainer:hover,
    .iconContainerFilter:hover {
        outline: none;
        box-shadow: 0 0 0 1px #E8BB00;
    }
    .iconContainer::placeholder {
        color: #A0A0A099;
    }
    .iconHeader{
        color: #A0A0A099;
        font-size: 25px;
        padding: .1rem .6rem .1rem .6rem;
    }
    input[type=time]::-webkit-datetime-edit-ampm-field {
    display: none;
    }
    .date{
        background-color: #FFF;
        border: 2px solid #adb3ba;
        color: #8f969e;
        border-radius: 10px;
        font-size: 16px;
        padding: 0.75rem 1rem 0.75rem 1rem;
        margin: 0rem 0 0.5rem;
        width: 50%;
    }
    .customDate{
        display: flex;
        margin: .5rem;
        width: 100%;
        cursor: pointer;
        background-color: #FFF;
        border-radius: 10px;
        padding: .6rem 0 .6rem 0;
        margin: .4rem 0;
    }
    .iconContainer input[type="date"]::-webkit-calendar-picker-indicator {
        background: transparent;
        bottom: 0;
        color: transparent;
        cursor: pointer;
        height: auto;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        width: auto;
    }
    /* .iconContainer input[type="date"]::-webkit-inner-spin-button,
    .iconContainer input[type="date"]::-webkit-calendar-picker-indicator {
        display: none;
        -webkit-appearance: none;
    } */
    .iconContainerFilter {
        display: flex;
        width: 80%;
        margin: 0 auto;
        cursor: pointer;
        background-color: #F4F4F4;
        border-radius: 10px;
        padding: .6rem 0 .6rem 0;
        margin-bottom: 2rem;
    }
    .inputIconFilter {
        background-color: #F4F4F4;
    }
    .inputIconFilter{
        border: none;
        width: 100%;
    }
    .inputIconFilter:focus{
        outline: none;
    }
    .inputIconFilter::placeholder{
        color: #B2B2B2;
    }
</style>
